import { Beige_Light_100, Gray_Light_900, Static_White } from '@croquiscom/zds';
import { Text } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/tokens';
import styled from '@emotion/styled';

import { Typography } from '@common/styles';

interface LandingBannerProps {
  imageSrc?: string;
  imageAlt?: string;
  buttonText?: string;
  onClickBanner?: () => void;
}

export const LandingBanner = ({ imageSrc, imageAlt, buttonText, onClickBanner }: LandingBannerProps) => {
  return (
    <Container onClick={onClickBanner}>
      <LandingImage src={imageSrc} alt={imageAlt} />
      <Content variant='Body_15_SemiBold' color={'textPrimary'}>
        지그재그 앱에서 더 편리하게
        {/* 지그재그 앱에서 더 편리하게 <b>3천원 쿠폰받기</b> 오픈 후 해당 워딩으로 대응 */}
      </Content>
      <GoToApp className={Typography.CAPTION_12_SEMIBOLD}>{buttonText}</GoToApp>
    </Container>
  );
};

const Container = styled.button`
  background: ${Beige_Light_100};
  width: 100%;
  height: 54px;
  padding: 0 17px;
  display: flex;
  align-items: center;
`;

const LandingImage = styled.img`
  width: 25px;
  margin-right: 8px;
`;

const Content = styled(Text)`
  margin-right: auto;
  b {
    color: ${vars.color.accentPinkPrimary};
  }
`;

const GoToApp = styled.span`
  display: inline-block;
  padding: 5px 12px;
  background-color: ${Gray_Light_900};
  color: ${Static_White};
  border-radius: 100px;
`;
