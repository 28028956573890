import { useQuery } from 'react-query';

import { ApiErrors } from '@common/api-request';

import { getUserName } from '../graphql/user-account.2';

/**
 * @public
 */
export type AuthState = 'authenticated' | 'pending' | 'failed';

export const useGetUserNameQuery = () => {
  return useQuery(
    ['getUserName'],
    async () => {
      const { data } = await getUserName(null, { show_alert: false, ignore_error: ApiErrors.NOT_LOGGED_IN });
      return data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
};
