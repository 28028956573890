declare const window: Window & {
  __TOSS_API__: {
    inAppLogin: (options?: { isSignUp: boolean }) => void;
  };
};

export const loginToss = async () => {
  try {
    window.__TOSS_API__?.inAppLogin({ isSignUp: true });
  } catch (error) {
    console.error('toss login error', error);
  }
};
