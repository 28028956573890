import { useQuery } from 'react-query';

import { getUserPurchaseAndInstallStatus } from '../graphql/is_first_order_user.2';
import { useGetUserNameQuery } from './useGetUserName';

const QUERY_KEY = 'getUserPurchaseAndInstallStatus';

export const useGetUserPurchaseAndInstallStatus = () => {
  const { data: user } = useGetUserNameQuery();
  const uuid = user?.user_account?.uuid ?? '';
  const { data } = useQuery(
    QUERY_KEY,
    async () => {
      const { data } = await getUserPurchaseAndInstallStatus(null, {
        show_alert: false,
      });
      return {
        is_first_order_user: data?.CmsIsFirstOrderUser,
        is_app_installed: data?.CmsIsAppInstalledUser,
      };
    },
    {
      enabled: !!uuid,
    },
  );

  return {
    isLogedIn: !!uuid,
    is_first_order_user: Boolean(data?.is_first_order_user),
    is_app_installed: Boolean(data?.is_app_installed),
  };
};
