import { useCallback, useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';

import { isKakaotalk, isMobile } from '@common/device-manager';
import { useAirbridgeDeeplinks, WEB_TO_APP_DEEP_LINK } from '@common/marketing-tracker';
import { useBenefitLandingPopup } from '@common/overlay-manager';

import { useGetUserPurchaseAndInstallStatus } from '../queries/useGetUserPurchaseAndInstallStatus';

const ZIGZAG_ICON_URL = 'https://cf.image-farm.s.zigzag.kr/original/cms/2025/01/09/202501090519309476_093291.png';

export function useLandingBannerService() {
  const cookies = new Cookies();
  const [utmSource, setUtmSource] = useState(cookies.get('utm_source') ?? '');
  const isNaverEp = utmSource?.includes('shopping_naver_all') || false;
  const [showAppInstallBanner, setShowAppInstallBanner] = useState(true);

  const { isLogedIn, is_app_installed, is_first_order_user } = useGetUserPurchaseAndInstallStatus();
  const { airbridgeDeeplinks } = useAirbridgeDeeplinks();
  const { open } = useBenefitLandingPopup();

  /**
   * 미노출 조건
   * 1. 로그인이 되어있지 않을 때
   * 2. 네이버 EP일 때
   * 3. 앱이 설치되어 있을 때
   * 4. 첫 주문 유저일 때
   * 5. 카카오톡 브라우저일 때
   * 6. 토스측에서 showAppInstallBanner를 false로 보낼 때
   */
  const hide =
    !isLogedIn || isNaverEp || is_app_installed || is_first_order_user || isKakaotalk() || !showAppInstallBanner;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlUtmSource = params.get('utm_source');
    const bannerParam = params.get('showAppInstallBanner');

    // URL 쿼리에 utm_source가 있으면 state에 세팅 (없으면 기존 쿠키값 유지)
    if (urlUtmSource) {
      setUtmSource(urlUtmSource);
    }

    setShowAppInstallBanner(bannerParam !== 'false'); // 토스측에서 hide 처리할 때 showAppInstallBanner=false로 보낼 수 있음
  }, []);

  const sendLog = useCallback(() => {}, []); // TODO: 로그 전송 처리 방식 장해지면 로그 추가

  const clickLandingBanner = useCallback(() => {
    if (isMobile()) {
      airbridgeDeeplinks({ deeplink: WEB_TO_APP_DEEP_LINK.HOME, ctaParams: { cta_param_1: '' } });
    } else {
      open();
    }
  }, [airbridgeDeeplinks, open]);

  return { clickLandingBanner, hide, bannerImage: ZIGZAG_ICON_URL, sendLog };
}
