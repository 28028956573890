import { LandingBanner } from '../components/LandingBanner';
import { useLandingBannerService } from '../services/useLandingBannerService';

export const LandingBannerContainer = () => {
  const { clickLandingBanner, hide, bannerImage, sendLog } = useLandingBannerService();

  if (hide) {
    return null;
  }

  return (
    <LandingBanner
      imageSrc={bannerImage}
      imageAlt='landing-banner'
      buttonText='앱으로 이동'
      onClickBanner={() => {
        clickLandingBanner();
        sendLog();
      }}
    />
  );
};
